import axios from 'axios';
import {BlobServiceClient} from '@azure/storage-blob';
import {BLOB_CONTAINER, BLOB_SERVICE_URL, THUMBNAIL_PREFIX, TOKEN_URL} from '../constants';


/**
 * Upload Blob to Azure
 * @returns {Promise}
 * @param params
 */
export const uploadBlobToAzure = (params: UploadBlobToAzureParams) => {
  const {SAS_KEY, file} = params;
  const blobContainerService = getBlobContainerService(SAS_KEY);
  const blobClient = blobContainerService.getBlockBlobClient(file.name);
  return blobClient.uploadBrowserData(file, {onProgress: console.log});
};

type UploadBlobToAzureParams = {
  SAS_KEY: string;
  file: File
}

function getBlobContainerService(SAS_KEY: string) {
  const blobServiceClient = new BlobServiceClient(`${BLOB_SERVICE_URL}/?${SAS_KEY}`);
  return blobServiceClient.getContainerClient(BLOB_CONTAINER);
}

export const deleteBlobFromAzure = (params: DeleteBlobFromAzureParams): any => {
  const {SAS_KEY, fileName} = params;
  const fileNames = [`${THUMBNAIL_PREFIX}-${fileName}`, fileName];
  const blobService = getBlobContainerService(SAS_KEY);
  return Promise.all(fileNames.map((fileName) => blobService.deleteBlob(fileName)));
};

export const getSAS = () => {
  return axios.get(TOKEN_URL, {timeout: 30000});
};

export const warmUp = () => {
  return axios.get(`${TOKEN_URL}?warm=true`, {timeout: 30000});
};

export type DeleteBlobFromAzureParams = {
  fileName: string;
  SAS_KEY: string;
}

export function downloadFile(data: File) {
  return new Promise((resolve) => {
    const contentType = 'application/octet-stream';

    const e = document.createEvent('MouseEvents');
    const a = document.createElement('a');
    a.download = data.name;
    a.href = window.URL.createObjectURL(data);
    a.dataset.downloadurl = [contentType, a.download, a.href].join(':');
    e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
    resolve(undefined);
  })
}