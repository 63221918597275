import React, {PureComponent} from 'react';
import AppContainer from './components/App.container';
import {Snackbar} from '@material-ui/core';
import {warmUp} from './utils/azureStorage';
import {IAppState} from './stores/appReducer';
import {Dispatch} from 'redux';
import {logout} from './stores/actions';
import {connect} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import Notifier from './components/app/Notifier';
import ErrorMessages from './components/app/ErrorMessages';

const twin: any = window;
const DURATION = 4000;

const initialState: IAppComponentLocalState = {
  errorMessages: [],
  snackbarOpen: false,
  snackbarMessage: ''
};

class App extends PureComponent<IAppComponentProps, IAppComponentLocalState> {
  private snackbarTimer: any;

  constructor(props: IAppComponentProps) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    twin.setSnackbar = this.setSnackbar;
    warmUp().catch(() => {/* */
    }); // warm up azure function for login
  }

  componentWillUnmount() {
    if (twin.setSnackbar) {
      twin.setSnackbar = undefined;
    }
    if (this.snackbarTimer) {
      clearTimeout(this.snackbarTimer);
    }
  }

  // componentDidCatch(error: Error, info: any) {
  //   // todo report Error
  //   this.setState({
  //     errorMessages: [
  //       ...this.state.errorMessages,
  //       `${error.message}\n ${error.stack}`
  //     ]
  //   });
  //   console.log('Error Caught');
  //   console.log(error.message);
  //   console.log(error.stack);
  //   console.log(JSON.stringify(info));
  // }

  setSnackbar = (message: string) => {
    if (this.snackbarTimer) {
      clearTimeout(this.snackbarTimer);
      this.setState({snackbarOpen: false, snackbarMessage: ''});
    }
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message
    }, () => {
      this.snackbarTimer = setTimeout(() => {
        this.setState({snackbarOpen: !this.state.snackbarOpen});
      }, DURATION);
    });
  };

  render() {
    const {snackbarOpen, snackbarMessage, errorMessages} = this.state;
    if (errorMessages.length) {
      return <ErrorMessages messages={errorMessages}/>;
    } else {
      return (
        <SnackbarProvider maxSnack={5}>
          <Notifier/>
          <AppContainer/>
          <Snackbar
            open={snackbarOpen}
            message={snackbarMessage}
          />
        </SnackbarProvider>
      );
    }
  }
}

function mapStateToProps(state: IAppState): IAppComponentMappedState {
  return {
    isLoggedIn: state.isLoggedIn,
    leftMenuOpen: state.leftMenuOpen,
    loginLocked: state.loginLocked
  };
}

function mapDispatchToProps(dispatch: Dispatch): IAppComponentActions {
  return {
    logout() {
      dispatch(logout());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

interface IAppComponentLocalState {
  errorMessages: string[];
  snackbarOpen: boolean;
  snackbarMessage: string;
}

interface IAppComponentMappedState {
  isLoggedIn: boolean;
  leftMenuOpen: boolean;
  loginLocked: boolean;
}

interface IAppComponentActions {
  logout(): void;
}

interface IAppComponentProps extends IAppComponentMappedState, IAppComponentActions {
}

