import React from 'react'
import {LoadingComponentProps} from 'react-loadable'
import Spinner from './Spinner';

const Loading = (props: LoadingComponentProps) => {
  if(props.error){
    return <div>Error! <button onClick={props.retry}>Retry</button></div>
  } else if(props.timedOut){
    return <div>Taking a long time... <button onClick={props.retry}>Retry</button></div>;
  } else if(props.pastDelay){
    return <Spinner />
  } else {
    return null;
  }
};

export default Loading;
