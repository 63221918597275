import {QUEUE_MESSAGE, REMOVE_MESSAGE} from './constants';

const initialState: IMessageState = {
  messages:[]
};

export const messageReducer = (state: IMessageState = initialState, action: any): IMessageState => {
  switch(action.type){
    case QUEUE_MESSAGE: {
      return {
        ...state,
        messages: [
          ...state.messages, {
          ...action.payload
          }
        ]
      }
    }
    case REMOVE_MESSAGE: {
      return {
        ...state,
        messages: state.messages.filter((message) => message.key !== action.payload.key )
      }
    }
    default: return state;
  }
};

export interface IMessageState {
  messages: IMessage[]
}

export interface IMessage extends ICreateMessage {
  key: number;
}

export interface ICreateMessage {
  message: string;
  options: IMessageOptions;
}

export interface IMessageOptions {
  variant?: TMessageVariants;
}

export type TMessageVariants = 'success' | 'warning' | 'error' | 'info';