const {
  REACT_APP_TOKEN_URL,
  REACT_APP_BLOB_SERVICE_URL,
  REACT_APP_BLOB_CONTAINER,
  REACT_APP_THUMBNAIL_PREFIX,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  NODE_ENV
} = process.env;


export const TOKEN_URL = REACT_APP_TOKEN_URL as string;
export const BLOB_SERVICE_URL = REACT_APP_BLOB_SERVICE_URL as string;
export const BLOB_CONTAINER = REACT_APP_BLOB_CONTAINER as string;
export const THUMBNAIL_PREFIX = REACT_APP_THUMBNAIL_PREFIX as string;
export const GOOGLE_MAPS_API_KEY = REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const IS_DEV = NODE_ENV !== 'production';