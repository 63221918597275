import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withMobileDialog} from '@material-ui/core'
import {IApplicationState} from '../../stores';
import {Dispatch} from 'redux';
import {closeLoginDialog, getUploadToken, ILoginParams, login, loginFail, queueMessage} from '../../stores/actions';
import {PASSWORD, USERNAME} from '../../stores/constants';
import {connect} from 'react-redux';
const twin: any = window;
const initialState: ILoginDialogState = {
  password: '',username:''
};

class LoginDialog extends React.Component<ILoginDialogProps,ILoginDialogState>{
  private handleUsernameInput: any;
  private handlePasswordInput: any;
  constructor(props: ILoginDialogProps) {
    super(props);
    this.state = initialState;
    this.handleUsernameInput = this._handleInput('username');
    this.handlePasswordInput = this._handleInput('password');
  }

  _handleInput(prop: 'password'|'username'){
    return (e: any) => {
      // @ts-ignore
      this.setState({
        [prop]: e.target.value
      })
    }
  }

  handleSubmit = () => {
    this.props.submit(this.state)
  };

  render(){
    const {close, isOpen, fullScreen} = this.props;
    const {handleUsernameInput, handlePasswordInput} = this;
    const handleEnterKey = (e: any) => {
      if (e.keyCode === 13) {
        this.handleSubmit();
      }
    };
    return (
      <Dialog
        onBackdropClick={close}
        open={isOpen}
        onClose={close}
        fullScreen={fullScreen}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <form onKeyUp={handleEnterKey} onSubmit={this.handleSubmit}>
            <TextField
              label="Username"
              onChange={handleUsernameInput}
              fullWidth={true}
              required={true}
            />
            <br/>
            <TextField
              label="Password"
              type="password"
              onChange={handlePasswordInput}
              fullWidth={true}
              required={true}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={this.handleSubmit}
            type="submit"
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToProps(state: IApplicationState):ILoginDialogMappedState {
  return {
    isOpen: state.app.loginDialogOpen
  }
}

function mapDispatchToProps(dispatch: Dispatch): ILoginDialogActions {
  return {
    close(){
      dispatch(closeLoginDialog())
    },
    submit(params: ILoginParams){
      // @ts-ignore
      return dispatch((dispatch: Dispatch, getState: ()=> IApplicationState) => {
        console.log('thunking');
        const {app:{failAttempts, loginLocked}} = getState();
        if(failAttempts >= 3 || loginLocked){
          dispatch(queueMessage({message:'Too many incorrect login attempts',options:{variant: 'error'}}));
          dispatch(closeLoginDialog());
          return;
        } else if(checkLogin(params)){
          twin.setSnackbar('Logged in successfully!');
          dispatch(login());
          dispatch(closeLoginDialog());
          dispatch(getUploadToken());
          return;
        } else {
          dispatch(queueMessage({message:'Incorrect Username or Password',options:{variant: 'error'}}));
          dispatch(loginFail())
        }
      })
    }
  }
}

export default withMobileDialog<any>({breakpoint: 'md'})(connect(mapStateToProps,mapDispatchToProps)(LoginDialog));

interface ILoginDialogProps extends ILoginDialogMappedState, ILoginDialogActions {
  fullScreen: boolean
}
interface ILoginDialogMappedState {
  isOpen: boolean;
}
interface ILoginDialogActions {
  close(): void;
  submit(params: ILoginParams): any;
}
interface ILoginDialogState {
  username: string;
  password: string;
}

function checkLogin(params: ILoginParams): boolean {
  return (params.username === USERNAME && params.password === PASSWORD )
}