import {combineReducers} from 'redux';
import {appReducer, IAppState} from './appReducer';
import {messageReducer, IMessageState} from './messageReducer';

export interface IApplicationState {
  app: IAppState,
  notifier: IMessageState
}

export default combineReducers({
  app: appReducer,
  notifier: messageReducer
})