import React, {PureComponent} from 'react'
import {
  AppBar,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  WithStyles
} from '@material-ui/core/'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MenuIcon from '@material-ui/icons/Menu'
import {withStyles} from '@material-ui/core/styles'
import {IApplicationState} from '../../stores';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {IAppState} from '../../stores/appReducer';
import {logout, openLoginDialog, toggleNavigationMenu} from '../../stores/actions';
import {RouteComponentProps} from 'react-router';

class AppHeading extends PureComponent<IAppHeadingProps, {loginMenuOpen: boolean; loginElem: any}> {
  constructor(props: IAppHeadingProps) {
    super(props);
    this.state = {loginMenuOpen: false, loginElem: null};
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // invoked immediately after DOM updates, not for initial 'render'
    if (!prevState.loginMenuOpen && this.state.loginMenuOpen) {
      setTimeout(this.toggleLoginMenu, 2000);
    }
  }

  toggleLoginMenu = (e: any) => {
    let loginElem,
      loginMenuOpen = !this.state.loginMenuOpen;
    if (e && loginMenuOpen) {
      loginElem = e.currentTarget;
    }
    this.setState({
      loginMenuOpen,
      loginElem
    })
  };

  render() {
    const {classes, toggleNavigationMenu, showLoginDialog, handleLogout, isLoggedIn, history} = this.props,
      {loginElem,loginMenuOpen} = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={toggleNavigationMenu} className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon/>
            </IconButton>
            <Typography
              className={classes.heading}
              color="inherit"
              variant="h6"
              onClick={() => history.push('/')}
            >
              SDUSD Aerial Construction Photos
            </Typography>
            <LoginMenu
              isLoggedIn={isLoggedIn}
              handleLogout={handleLogout}
              history={history}
              open={loginMenuOpen}
              showLoginDialog={showLoginDialog}
              anchorEl={loginElem}
            />
            <IconButton
              onClick={this.toggleLoginMenu}
              color="inherit"
              aria-haspopup="true"
              aria-owns={loginElem ? 'login-menu' : ''}
            >
              <MoreVertIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

const styles = createStyles({
  root: {
    flexGrow: 1,
  },
  heading: {
    flex: 1,
    cursor: 'pointer'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

// @ts-ignore
function mapStateToProps(state: IApplicationState): IAppHeadingState {
  return {
    isLoggedIn: state.app.isLoggedIn
  }
}
// @ts-ignore
function mapDispatchToProps(dispatch: Dispatch): IAppHeadingActions {
  return {
    toggleNavigationMenu(){
      dispatch(toggleNavigationMenu())
    },
    showLoginDialog(){
      dispatch(openLoginDialog())
    },
    handleLogout(){
      dispatch(logout())
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(AppHeading));

interface IAppHeadingProps extends WithStyles<typeof styles>,IAppHeadingState, IAppHeadingActions,RouteComponentProps {}

interface IAppHeadingState extends Pick<IAppState,'isLoggedIn'>{}
interface IAppHeadingActions {
  toggleNavigationMenu(): void;
  showLoginDialog(): void;
  handleLogout(): void;
}

const LoginMenu = (props: ILoginMenuProps) => {
  const {showLoginDialog, isLoggedIn, handleLogout, anchorEl, open, history} = props;
  const menuText = isLoggedIn ? 'Sign Out' : 'Sign In';
  const doLogout = () => {
    handleLogout();
    history.push('/');
  };
  const menuClickHandler = isLoggedIn ? doLogout : showLoginDialog;
  return (
    <Menu
      id="login-menu"
      anchorEl={anchorEl}
      open={open}
    >
      <MenuItem onClick={menuClickHandler}>{menuText}</MenuItem>
    </Menu>
  );
};

interface ILoginMenuActions {
  handleLogout(): void;
  showLoginDialog(): void;
}

interface ILoginMenuState {
  open: boolean;
  isLoggedIn: boolean;
  anchorEl: any
}

interface ILoginMenuProps extends ILoginMenuState, ILoginMenuActions{
  history: any
}
