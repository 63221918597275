import {
  GET_SAS_TOKEN_FAIL,
  IMAGE_VIEWER_CLOSE,
  IMAGE_VIEWER_OPEN,
  LEFT_MENU_CLOSE,
  LEFT_MENU_TOGGLE,
  LOGIN,
  LOGIN_DIALOG_CLOSE,
  LOGIN_DIALOG_OPEN,
  LOGIN_FAIL,
  LOGOUT,
  MAP_GALLERY_CLOSE,
  MAP_GALLERY_OPEN,
  QUEUE_MESSAGE,
  RECEIVE_SAS_TOKEN,
  REMOVE_MESSAGE,
  TAppStateActions,
} from './constants';
import {Dispatch} from 'redux';
import {IApplicationState} from './index';
import {getSAS} from '../utils/azureStorage';
import {IAppState} from './appReducer';
import {IImageViewerProp} from '../components/App.container';
import {ICreateMessage, IMessage} from './messageReducer';

export const login = (): IAction<undefined> => {
  return {
    type: LOGIN
  }
};

export const loginFail = (): IAction<undefined> => {
  return {
    type: LOGIN_FAIL
  }
};

export const logout = (): IAction<undefined> => {
  return {
    type: LOGOUT
  }
};

export const openLoginDialog = (): IAction<undefined> => {
  return {
    type: LOGIN_DIALOG_OPEN
  }
};

export const closeLoginDialog = (): IAction<undefined> => {
  return {
    type: LOGIN_DIALOG_CLOSE
  }
};

export const toggleNavigationMenu = (): IAction<undefined> => {
  return {
    type: LEFT_MENU_TOGGLE
  }
};

export const closeNavigationMenu = (): IAction<undefined> => {
  return {
    type: LEFT_MENU_CLOSE
  }
};

export const getUploadToken = (state?: IAppState): any => {
  return (dispatch: Dispatch, getState: ()=> IApplicationState) => {
    // check if max errors received
    let sasErrors: any;
    if(state){
      sasErrors = state.sasErrors;
    } else {
      sasErrors = getState().app.sasErrors;
    }
    if(sasErrors >= 5){
      throw Error(`System Error\,Unable to get Client Authorization token, please try again later.`)
    } else {
      return getSAS()
        .then(({data: {expiryDate, token, issDate}}) => {
          const SAS_KEY = decodeURIComponent(token);
          dispatch({
            type: RECEIVE_SAS_TOKEN,
            payload: {SAS_KEY,SAS_KEY_EXPIRY:expiryDate,SAS_KEY_ISS:issDate,sasErrors:0}
          })
        })
        .catch((err) => {
          dispatch({type: GET_SAS_TOKEN_FAIL});
        })
    }
  }
  ;
};

export const openImageViewer = (payload: IImageViewerProp): IAction<IImageViewerProp> => {
  return {
    type: IMAGE_VIEWER_OPEN,
    payload
  }
};

export const closeImageViewer = (): IAction<undefined> => {
  return {
    type: IMAGE_VIEWER_CLOSE
  }
};

export const openMapGallery = (): IAction<undefined> => {
  return {
    type: MAP_GALLERY_OPEN
  }
};

export const closeMapGallery = (): IAction<undefined> => {
  return {
    type: MAP_GALLERY_CLOSE
  }
};

export const queueMessage = (message: ICreateMessage): AllRequired<IAction<IMessage>> => ({
  type: QUEUE_MESSAGE,
  payload: {
    key: new Date().getTime() + Math.random(),
    ...message
  }
});

export const removeMessage = (key: number): AllRequired<IAction<{key: number;}>> => ({
  type: REMOVE_MESSAGE,
  payload: {key}
});

export interface IAction<T> {
  type: TAppStateActions;
  payload?: T
}

export interface ILoginParams {
  username: string;
  password: string;
}

export type AllRequired<T> = {
  [K in keyof T]-?: T[K];
};
