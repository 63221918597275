import React, {PureComponent} from 'react'
import AppHeading from './app/AppHeading'
import LoginDialog from './app/LoginDialog'
import LeftMenu from './app/LeftMenu'
import Loadable from 'react-loadable';
import Loading from './app/Loading';
import ImageViewer from './app/ViewImage'
import {IApplicationState} from '../stores';
import {Dispatch} from 'redux';
import {getUploadToken, toggleNavigationMenu} from '../stores/actions'
import {connect} from 'react-redux';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

// code split routes
const MapContainer = Loadable({
  loader: () => import('./Map/Map.container'),
  loading: Loading
});
const SearchContainer = Loadable({
  loader: () => import('./Search/Search.container'),
  loading: Loading
});
const UploadPhotosContainer = Loadable({
  loader: () => import('./UploadPhotos/ImageUpload.container'),
  loading: Loading
});
const ManagePhotosContainer = Loadable({
  loader: () => import('./ManagePhotos/ManagePhotos.container'),
  loading: Loading
});
const DistributionListContainer = Loadable({
  loader: () => import('./DistributionList/DistributionLinks.container'),
  loading: Loading
});
const PlanRouteContainer = Loadable({
  loader: () => import('./RoutePlanner/RoutePlanner.container'),
  loading: Loading
});
const RouteLocationsContainer = Loadable({
  loader: () => import('./RouteLocations/RouteLocations.container'),
  loading: Loading
});

const initialState: IAppComponentState = {
  mapGalleryOpen: false,
  imageViewerOpen: false,
  imageViewerSource: '',
  imageViewerPrevNextButtons: false,
  imageViewerPrevButtonFn: () => {/* */},
  imageViewerNextButtonFn: () => {/**/}
};

class App extends PureComponent<IAppProps, IAppComponentState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = initialState;
  }

  componentWillMount() {
    document.title = 'SDUSD Aerial Construction Photos'
  }

  render() {
    const {isLoggedIn} = this.props;
    return (
      <React.Fragment>
        <ImageViewer />
        <BrowserRouter>
          <React.Fragment>
            <Route path="/" component={AppHeading}/>
            <Route path="/" component={LeftMenu}/>
            <Switch>
              <Route path="/" exact={true} component={MapContainer} />
              <Route path="/search" exact={true} component={SearchContainer} />
              {isLoggedIn ? (
                <React.Fragment>
                  <Route path="/upload" exact={true} component={UploadPhotosContainer}/>
                  <Route path="/managePhotos" exact={true} component={ManagePhotosContainer}/>
                  <Route path="/distributionList" exact={true} component={DistributionListContainer}/>
                  <Route path="/routeLocations" exact={true} component={RouteLocationsContainer}/>
                  <Route path="/routePlanner" exact={true} component={PlanRouteContainer}/>
                </React.Fragment>
              ): <Redirect to="/"/>}
              <Redirect to="/"/>
            </Switch>
          </React.Fragment>
        </BrowserRouter>
        <LoginDialog />
      </React.Fragment>
    );
  }
}

interface IAppProps extends IAppMappedState, IAppActions {}

interface IAppComponentState {}

interface IAppMappedState {
  isLoggedIn: boolean;
  navigationMenuOpen: boolean;
  SAS_KEY: string;
  SAS_KEY_EXPIRY: string;
}
interface IAppActions {
  getUploadToken(): void;
  toggleAdminMenu(): void;
}

function mapStateToProps(state: IApplicationState): IAppMappedState {
  return {
    isLoggedIn: state.app.isLoggedIn,
    SAS_KEY: state.app.SAS_KEY,
    SAS_KEY_EXPIRY: state.app.SAS_KEY_EXPIRY,
    navigationMenuOpen: state.app.leftMenuOpen
  }
}

function mapDispatchToProps(dispatch: Dispatch): IAppActions {
  return {
    getUploadToken(){
      dispatch(getUploadToken())
    },
    toggleAdminMenu(){
      dispatch(toggleNavigationMenu())
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);

export interface IImageViewerProp {
  imageViewerSource: string;
  showPrevNextButtons: boolean;
  prevButton(): void;
  nextButton(): void;
}