import React, {PureComponent} from 'react'
import LeftArrow from '@material-ui/icons/ChevronLeft'
import RightArrow from '@material-ui/icons/ChevronRight'
import {
  createStyles,
  Dialog,
  DialogActions,
  withStyles,
  WithStyles
} from '@material-ui/core';
import {IApplicationState} from '../../stores';
import {Dispatch} from 'redux';
import {closeImageViewer} from '../../stores/actions';
import {connect} from 'react-redux';
import AppButton from './AppButton';

class ViewImage extends PureComponent<IViewImageProps, IViewImageState> {
  constructor(props: IViewImageProps) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  imgLoaded = () => {
    this.setState({loaded: true})
  };

  close = () => {
    this.setState({loaded: false}, this.props.close)
  };

  render() {
    const {close, fileName} = this.props;
    const {classes,isOpen, prevNextButtons, nextButton, prevButton} = this.props;
    const {loaded} = this.state;
    const actions = [
      <AppButton color="primary" onClick={close} key="one">Close</AppButton>,
      <AppButton href={fileName} color="secondary" download={true} key="two">Download</AppButton>
    ];
    return (
      <Dialog
        open={isOpen}
        fullScreen={true}
        onClose={close}
        onBackdropClick={close}
      >
        <img alt="" style={{display: 'none'}} src={fileName} onLoad={this.imgLoaded}/>
        {loaded && (
          <div>
            {prevNextButtons && <LeftArrow className="left-arrow arrow" onClick={prevButton}/>}
            <img alt="" className={classes.img} src={fileName}/>
            {prevNextButtons && <RightArrow className="right-arrow arrow" onClick={nextButton}/>}
          </div>
        )}
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    )
  }
}


const styles = createStyles({
  root: {
    maxWidth: '100%',
    position: 'relative',
    top: '2%'
  },
  img: {
    height: 'auto', maxWidth: '100%', display: 'block'
  }
});

function mapStateToProps({app}: IApplicationState): IViewImageMappedState {
  return {
    fileName: app.imageViewerSource,
    isOpen: app.imageViewerOpen,
    nextButton: app.imageViewerNextButtonFn,
    prevButton: app.imageViewerPrevButtonFn,
    prevNextButtons: app.imageViewerShowPrevNextButtons
  }
}

function mapDispatchToProps(dispatch: Dispatch): IViewImageActions {
  return {
    close(){
      dispatch(closeImageViewer())
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(ViewImage));

interface IViewImageProps extends WithStyles<typeof styles>, IViewImageMappedState, IViewImageActions {}

interface IViewImageMappedState {
  fileName: string;
  isOpen: boolean;
  prevNextButtons: boolean;
  prevButton(): void;
  nextButton(): void;
}

interface IViewImageActions {
  close(): void;
}

interface IViewImageState {
  loaded: boolean;
}