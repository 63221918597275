import './index.css';
import './paginate.css';
import './toast.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, compose, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import reducers from './stores';
import {QUEUE_MESSAGE, REFRESH_MESSAGE, RELOAD_APP} from './stores/constants';

const twin: any = window;
const composeEnhancers = twin.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const applicationStore = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

const ConnectedApp = (
  <Provider store={applicationStore}>
      <App />
  </Provider>
);

ReactDOM.render(ConnectedApp, document.getElementById('root'));
registerServiceWorker()
  .then((message?: string) => {
    if (message) {
      applicationStore.dispatch({
        type: QUEUE_MESSAGE,
        payload: {
          message,
          options: {
            variant: 'info'
          }
        }
      });
      if (message === REFRESH_MESSAGE){
        setTimeout(() => {
          applicationStore.dispatch({
            type: RELOAD_APP
          })
        }, 3000)
      }
    }
  });
