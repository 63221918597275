import React from 'react';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Paper, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ErrorMessages = (props: IErrorMessagesProps) => {
  return (
    <Paper>
      {props.messages.map((message, index) => (<ErrorMessage idx={index+1} message={message}/>))}
    </Paper>
  );
};

export default ErrorMessages;

interface IErrorMessagesProps {
  messages: string[]
}

const ErrorMessage = (props: IErrorMessageProps) => {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
        <Typography>Error Message {props.idx}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{props.message}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

interface IErrorMessageProps {
  message: string;
  idx: number;
}