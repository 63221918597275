import React from 'react'
import FileUpload from '@material-ui/icons/CloudUpload'
import ManageFile from '@material-ui/icons/Edit'
import SearchIcon from '@material-ui/icons/Search'
import MapIcon from '@material-ui/icons/Map'
import RouteIcon from '@material-ui/icons/Explore'
import {Divider, Drawer, IconButton, MenuItem} from '@material-ui/core';
import {IApplicationState} from '../../stores';
import {Dispatch} from 'redux';
import {closeNavigationMenu} from '../../stores/actions';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';

const LeftMenu = (props: ILeftMenuProps) => {
  const {isOpen, close, isLoggedIn, history } = props;
  const goTo = (path: string) => {
    history.push(path);
    setTimeout(close,500)
  };
  const adminItems = [
      <Divider key="am-divider"/>,
      (
        <MenuItem key="am-upload" onClick={() => goTo('upload')}>
          <IconButton><FileUpload/></IconButton>Upload Photos
        </MenuItem>
      ),
      (
        <MenuItem key="am-manage" onClick={() => goTo('/managePhotos')}>
          <IconButton><ManageFile/></IconButton> Manage Photos</MenuItem>
      ),
      (
        <MenuItem key="am-distribution" onClick={() => goTo('/distributionList')}>
          <IconButton><ManageFile/></IconButton> Distrubtion List</MenuItem>
      ),
      (
        <MenuItem key="am-planner" onClick={() => goTo('/routePlanner')}>
          <IconButton><RouteIcon/></IconButton> Plan Route</MenuItem>
      ),
      (
        <MenuItem key="am-route-locations" onClick={() => goTo('/routeLocations')}><IconButton><RouteIcon/></IconButton>Route Locations</MenuItem>
      )
    ],
    statefulComponents = [];

  if (isLoggedIn) {
    statefulComponents.push(...adminItems);
  }

  return (
    <Drawer
      open={isOpen}
      anchor="left"
      ModalProps={{onBackdropClick: close}}
    >
      <MenuItem onClick={() => goTo('/')}>
        <IconButton><MapIcon/></IconButton>View Map</MenuItem>
      <MenuItem onClick={() => goTo('/search')}>
        <IconButton><SearchIcon/></IconButton> Search</MenuItem>
      {statefulComponents}
      <Divider/>
      <MenuItem onClick={close}>Close</MenuItem>
    </Drawer>
  )
};

function mapStateToProps(state: IApplicationState): ILeftMenuState {
  return {
    isLoggedIn: state.app.isLoggedIn,
    isOpen: state.app.leftMenuOpen
  }
}

function mapDispatchToProps(dispatch: Dispatch): Pick<ILeftMenuActions, 'close'> {
  return {
    close() {
      dispatch(closeNavigationMenu())
    }
  }
}

interface ILeftMenuProps extends RouteComponentProps, ILeftMenuState, ILeftMenuActions {}

interface ILeftMenuState {
  isOpen: boolean;
  isLoggedIn: boolean;
}

interface ILeftMenuActions {
  close(): void;
}

export default connect(mapStateToProps,mapDispatchToProps)(LeftMenu);