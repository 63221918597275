export const LOGIN = 'LOGIN';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGIN_DIALOG_OPEN = 'LOGIN_DIALOG_OPEN';
export const LOGIN_DIALOG_CLOSE = 'LOGIN_DIALOG_CLOSE';
export const LEFT_MENU_TOGGLE = 'LEFT_MENU_TOGGLE';
export const LEFT_MENU_OPEN = 'LEFT_MENU_OPEN';
export const LEFT_MENU_CLOSE = 'LEFT_MENU_CLOSE';
export const RECEIVE_SAS_TOKEN = 'RECEIVE_SAS_TOKEN';
export const GET_SAS_TOKEN = 'GET_SAS_TOKEN';
export const GET_SAS_TOKEN_FAIL = 'GET_SAS_TOKEN_FAIL';
export const MAP_GALLERY_OPEN = 'MAP_GALLERY_OPEN';
export const MAP_GALLERY_CLOSE = 'MAP_GALLERY_CLOSE';
export const IMAGE_VIEWER_OPEN = 'IMAGE_VIEWER_OPEN';
export const IMAGE_VIEWER_CLOSE = 'IMAGE_VIEWER_CLOSE';
export const QUEUE_MESSAGE = 'QUEUE_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const RELOAD_APP = 'RELOAD_APP';
export const REFRESH_MESSAGE = 'Application Updated! Page will reload automatically';

export const USERNAME = 'admin';
export const PASSWORD = '2357Twice';
export const LOGIN_STATUS = 'loggedIn';

export type TAppStateActions = 'LOGIN' | 'LOGOUT' | 'LEFT_MENU_OPEN' | 'LEFT_MENU_CLOSE' | 'LOGIN_DIALOG_OPEN'
  | 'LOGIN_DIALOG_CLOSE' | 'LEFT_MENU_OPEN' | 'LEFT_MENU_CLOSE' | 'GET_SAS_TOKEN' | 'RECEIVE_SAS_TOKEN' | 'GET_SAS_TOKEN_FAIL' | 'LOGIN_FAIL' | 'LEFT_MENU_TOGGLE' | 'IMAGE_VIEWER_OPEN' | 'IMAGE_VIEWER_CLOSE' | 'MAP_GALLERY_OPEN' | 'MAP_GALLERY_CLOSE' | 'QUEUE_MESSAGE' | 'REMOVE_MESSAGE' | 'RELOAD_APP';