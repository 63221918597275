import React, {PureComponent} from 'react'
import {IMessage} from '../../stores/messageReducer';
import {IApplicationState} from '../../stores';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {removeMessage} from '../../stores/actions';
import {ProviderContext, withSnackbar} from 'notistack';

const initialState: INotifierState = {
  displayed: []
};

class Notifier extends PureComponent<INotifierProps, INotifierState> {
  constructor(props: INotifierProps) {
    super(props);
    this.state = initialState;
  }

  storeDisplayed = (key: number): void => {
    this.setState({
      displayed: [...this.state.displayed, key]
    })
  };

  render() {
    const {messages, enqueueSnackbar, removeMessage} = this.props;
    const {displayed} = this.state;
    messages.forEach((message, index) => {
      if (displayed.indexOf(message.key) > -1) {
        return;
      } else {
        // add message to displayed to prevent duplicate messages
        this.storeDisplayed(message.key);
        // display message
        enqueueSnackbar(message.message, message.options);
        // remove message from queue
        removeMessage(message.key);
      }
    });
    return <span/>;
  }
}

function mapStateToProps(state: IApplicationState): IMappedState {
  return {
    messages: state.notifier.messages
  };
}

function mapDispatchToProps(dispatch: Dispatch): IMappedActions {
    return {
      removeMessage(key: number){
        dispatch(removeMessage(key))
      }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));

interface IMappedState {
  messages: IMessage[];
}

interface IMappedActions {
  removeMessage(key: number): void;
}

interface INotifierProps extends IMappedState, ProviderContext, IMappedActions {
}

interface INotifierState {
  displayed: number[];
}