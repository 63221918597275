import React from 'react'
import {Button, ButtonProps} from '@material-ui/core';

const AppButton: React.FC<ButtonComponentProps> = (props) => {
 const {children, ...rest} = props;
  return (
    <Button
      {...rest}
      variant="contained"
    >{props.children}</Button>
  )
};

export default AppButton;

interface ButtonComponentProps extends Omit<ButtonProps, 'variant'> {
  download?: boolean;
}