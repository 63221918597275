import * as cookie from '../utils/cookieStorage'
import {
  GET_SAS_TOKEN,
  GET_SAS_TOKEN_FAIL, IMAGE_VIEWER_CLOSE, IMAGE_VIEWER_OPEN, LEFT_MENU_CLOSE,
  LEFT_MENU_TOGGLE,
  LOGIN,
  LOGIN_DIALOG_CLOSE,
  LOGIN_DIALOG_OPEN,
  LOGIN_FAIL,
  LOGOUT, MAP_GALLERY_CLOSE, MAP_GALLERY_OPEN, RECEIVE_SAS_TOKEN, RELOAD_APP
} from './constants';
import {getUploadToken, IAction} from './actions';
import {applicationStore} from '../index';
import {IImageViewerProp} from '../components/App.container';

export const ImageViewerDefaultState: IImageViewerState = {
  imageViewerSource: '',
  imageViewerShowPrevNextButtons: false,
  imageViewerOpen: false,
  imageViewerPrevButtonFn: () => {/* */},
  imageViewerNextButtonFn: () => {/* */}
};

const initialState: IAppState = {
  errors: '',
  failAttempts: 0,
  isLoggedIn: Boolean(cookie.get(LOGIN)),
  leftMenuOpen: false,
  loginLocked: false,
  SAS_KEY:'',
  SAS_KEY_EXPIRY:'',
  SAS_KEY_ISS:'',
  sasErrors: 0,
  loginDialogOpen: false,
  mapGalleryOpen: false,
  ...ImageViewerDefaultState
};

export const appReducer = (state: IAppState = initialState, action: IAction<any>): IAppState => {
  switch(action.type){
    case LOGIN: {
      // set cookie expiry two weeks
      cookie.set(LOGIN, 'true', {expires: new Date(new Date().getTime() + (14 * 24 * 60 * 60 * 1000))});
      return {
        ...state,
        errors:'',
        failAttempts: 0,
        isLoggedIn: true
      };
    }
    case LOGIN_FAIL: {
      // check fail attempts, if greater than 3, lock them out
      const {failAttempts} = state;
      if(failAttempts > 3){
        return {
          ...state,
          loginLocked: true
        }
        } else {
        return {
          ...state,
          failAttempts: failAttempts+1
        }
      }
    }
    case LOGOUT: {
      cookie.remove(LOGIN);
      return {
        ...state,
        isLoggedIn: false
      }
    }
    case LEFT_MENU_TOGGLE: {
      return {
        ...state,
        leftMenuOpen: !state.leftMenuOpen
      }
    }
    case LEFT_MENU_CLOSE: {
      return {
        ...state,
        leftMenuOpen: false
      }
    }
    case LOGIN_DIALOG_OPEN: {
      return {
        ...state,
        loginDialogOpen: true
      }
    }
    case LOGIN_DIALOG_CLOSE: {
      return {
        ...state,
        loginDialogOpen: false
      }
    }
    case GET_SAS_TOKEN: {
      applicationStore.dispatch(getUploadToken(state));
      return state;
    }
    case RECEIVE_SAS_TOKEN: {
      const {SAS_KEY,SAS_KEY_EXPIRY,SAS_KEY_ISS} = action.payload;
      return {
        ...state,
        SAS_KEY,
        SAS_KEY_EXPIRY,
        SAS_KEY_ISS
      }
    }
    case GET_SAS_TOKEN_FAIL: {
      const {sasErrors} = state;
      return {
        ...state,
        sasErrors: sasErrors +1
      }
    }
    case IMAGE_VIEWER_OPEN: {
      const {imageViewerSource,showPrevNextButtons,prevButton,nextButton} = action.payload as IImageViewerProp;
      return {
        ...state,
        imageViewerOpen: true,
        imageViewerSource,
        imageViewerShowPrevNextButtons: showPrevNextButtons,
        imageViewerPrevButtonFn: prevButton,
        imageViewerNextButtonFn: nextButton
      };
    }
    case IMAGE_VIEWER_CLOSE: {
      return {
        ...state,
        ...ImageViewerDefaultState
      }
    }
    case MAP_GALLERY_OPEN: {
      return {
        ...state,
        mapGalleryOpen: true
      }
    }
    case MAP_GALLERY_CLOSE: {
      return {
        ...state,
        mapGalleryOpen: false
      }
    }
    case RELOAD_APP: {
      window.location.reload(true);
      return state;
    }
    default: return state;
  }
};

export interface IAppState extends ISAS, IImageViewerState {
  errors: string;
  loginLocked: boolean;
  failAttempts: number;
  sasErrors: number;
  isLoggedIn: boolean;
  leftMenuOpen: boolean;
  loginDialogOpen: boolean;
  mapGalleryOpen: boolean;
}

export interface ISAS {
  SAS_KEY: string;
  SAS_KEY_EXPIRY: string;
  SAS_KEY_ISS: string;
}

export interface IImageViewerState {
  imageViewerOpen: boolean;
  imageViewerSource: string;
  imageViewerShowPrevNextButtons: boolean;
  imageViewerPrevButtonFn(): void;
  imageViewerNextButtonFn(): void;
}